///////////////////////////////////////////////////////////////////////// hola2
///// IMPORT
import './main.css'
import { Clock, Scene, LoadingManager, WebGLRenderer, sRGBEncoding, Group, PerspectiveCamera, DirectionalLight, PointLight, MeshPhongMaterial} from 'three'
import { Mesh, MeshStandardMaterial, SpotLight, Vector3, Euler, Matrix4, CatmullRomCurve3, BufferGeometry, LineBasicMaterial, Line } from 'three'; // Add this line
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { CapsuleGeometry } from 'three/examples/jsm/geometries/CapsuleGeometry.js';
import { Flow } from "three/examples/jsm/modifiers/CurveModifier.js";
import { LoadGLTFByPath, getOBjectByName } from './helpers/ModelHelper.js';
import { loadCurveFromJSON } from './curveTools/CurveMethods.js'
import { LottieAnimation } from './helpers/LottieHelper.js';


/////////////////////////////////////////////////////////////////
// Añadir Funciones Vimeo Lightbox
// Variables para controlar el estado de las animaciones

// Variables de control globales
let isAnimationPaused = false;
let demoAnimation;
let lightboxInitialized = false;

// Inicializar el Lightbox
function initializeLightbox() {
    if (lightboxInitialized) return;
    
    console.log('Initializing lightbox functionality');
    
    // Add event listener for ver_demo spans
    const verDemoSpans = document.querySelectorAll('.ver-demo');
    console.log('Found ver-demo elements:', verDemoSpans.length);
    
    verDemoSpans.forEach(span => {
        console.log('Adding click handlers to span:', span);
        
        // Add click event directly to the span
        span.addEventListener('click', handleClick);
        
        // Add click event to parent link if it exists
        const parentLink = span.closest('.demo-link');
        if (parentLink) {
            console.log('Found parent demo-link');
            parentLink.addEventListener('click', handleClick);
        }
    });
    
    lightboxInitialized = true;
}

// Función manejadora del click
function handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log('Click detected');
    console.log('Event target:', e.target);

    // Buscar el elemento .ver-demo más cercano en el árbol de nodos
    let span = e.target.closest('.ver-demo');

    // Si no se encuentra, buscar si el evento ocurrió dentro del link padre
    if (!span) {
        const parentLink = e.target.closest('.demo-link');
        if (parentLink) {
            console.log('Searching .ver-demo within parent demo-link');
            span = parentLink.querySelector('.ver-demo');
        }
    }

    // Si aún no se encuentra el elemento .ver-demo, salir de la función
    if (!span) {
        console.log('No .ver-demo element found near event target');
        return;
    }
    
    openLightbox(span);
}


// Función para abrir el lightbox

function openLightbox(spanElement) {
    console.log('Opening lightbox');
    
    // Pausar animaciones si existe y tiene el método pause
    if (demoAnimation && typeof demoAnimation.pause === 'function') {
        console.log('Pausing Lottie animation');
/////   demoAnimation.pause();
        demoAnimation.pauseAnimation();
                
    } else {
        console.log('demoAnimation is not initialized or does not have a pause method');
    }
    isAnimationPaused = true;
    
    const videoId = spanElement.getAttribute('data-video-id');
    console.log('Video ID:', videoId);
    
    if (!videoId) {
        console.log('No video ID found');
        return;
    }
    
    let lightbox = document.getElementById('lightbox');
    if (!lightbox) {
        console.log('Creating new lightbox');
        lightbox = document.createElement('div');
        lightbox.id = 'lightbox';
        lightbox.className = 'lightbox';
        
        const lightboxContent = document.createElement('div');
        lightboxContent.className = 'lightbox-content';
        
        const iframe = document.createElement('iframe');
        iframe.id = 'vimeo-video';
        iframe.src = `https://player.vimeo.com/video/${videoId}`;
        iframe.width = '800';
        iframe.height = '450';
        iframe.frameBorder = 0;
        iframe.allow = 'autoplay; fullscreen; picture-in-picture';
        iframe.allowFullscreen = true;
        
        const closeButton = document.createElement('button');
        closeButton.className = 'close-lightbox';
        closeButton.textContent = 'X';
        closeButton.addEventListener('click', (e) => {
            console.log('Close button clicked');
            e.stopPropagation();
            closeLightbox();
        });

        lightboxContent.appendChild(iframe);
        lightboxContent.appendChild(closeButton);
        lightbox.appendChild(lightboxContent);
        document.body.appendChild(lightbox);
        
        lightbox.addEventListener('click', (e) => {
            if (e.target === lightbox) {
                console.log('Clicked outside video');
                closeLightbox();
            }
        });
    } else {
        console.log('Updating existing lightbox');
        const iframe = lightbox.querySelector('#vimeo-video');
        if (iframe) {
            iframe.src = `https://player.vimeo.com/video/${videoId}`;
        }
    }
    
    console.log('Displaying lightbox');
    lightbox.style.display = 'flex';
}


// Función para cerrar el lightbox
function closeLightbox() {
    console.log('Closing lightbox');
    const lightbox = document.getElementById('lightbox');
    if (lightbox) {
        const vimeoVideoIframe = document.getElementById('vimeo-video');
        if (vimeoVideoIframe) {
            vimeoVideoIframe.src = '';
        }
        lightbox.style.display = 'none';
        
        // Reanudar animaciones
        if (demoAnimation && typeof demoAnimation.play === 'function') {
            console.log('Resuming Lottie animation');
/////////   demoAnimation.play();
            demoAnimation.playAnimation(); // Reproducir la animación cuando se cierra el lightbox
        } else {
            console.log('demoAnimation is not initialized or does not have a play method');
        }
        isAnimationPaused = false;
    }
}

// Asegurarse de que el lightbox se inicialice cuando el DOM esté listo
if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initializeLightbox);
} else {
    initializeLightbox();
}


//////////////////////////////////////////////////////////////////////////////////
// Load the JSON file containing the curve points
const curvePaths = [
    './models/curve1JSON.json',
    './models/curve2JSON.json',
    './models/curve3JSON.json',
];
  
const colors = [0xff0000, 0x00ff00, 0x0000ff];
  
const lines = [];


// Función para cargar y procesar un archivo JSON
function loadCurve(path, color) {
    return fetch(path)
      .then((response) => response.json())
      .then((data) => {
        // Extrae los puntos de la curva del JSON
        const curvePoints = data.points.map((point) => new Vector3(point.x, point.y, point.z));
  
        // Crea una curva CatmullRomCurve3 a partir de los puntos
        const curve = new CatmullRomCurve3(curvePoints);
  
        // Crea una geometría para los trazos discontinuos
        const strokeGeometry = new BufferGeometry();
        const strokeMaterial = new LineBasicMaterial({ color });
        
        const numStrokes = 50; // Número de trazos
        const points = [];
        
        for (let i = 0; i < numStrokes; i++) {
          const t = i / (numStrokes - 3);
          const strokeLength = Math.random() * 0.04 + 0.01; // Longitud aleatoria entre 1% y 6% de la curva
          const startPoint = curve.getPoint(t);
          const endPoint = curve.getPoint(Math.min(t + strokeLength, 1));
          
          points.push(startPoint, endPoint);
        }
        
        strokeGeometry.setFromPoints(points);
        return new Line(strokeGeometry, strokeMaterial);
      });
}
  
// Carga y creación de líneas
for (let i = 0; i < curvePaths.length; i++) {
  lines.push(loadCurve(curvePaths[i], colors[i]));
}
  
// Añadir las líneas a la escena
Promise.all(lines).then((loadedLines) => {
  loadedLines.forEach((line) => scene.add(line));
});


/////////////////////////////////////////////////////////////////////////
//// LOADING MANAGER
const ftsLoader = document.querySelector(".lds-roller")
const looadingCover = document.getElementById("loading-text-intro")
const loadingManager = new LoadingManager()

loadingManager.onLoad = function() {

    document.querySelector(".main-container").style.visibility = 'visible'
    document.querySelector("body").style.overflow = 'auto'

    const yPosition = {y: 0}
    
    new TWEEN.Tween(yPosition).to({y: 100}, 900).easing(TWEEN.Easing.Quadratic.InOut).start()
    .onUpdate(function(){ looadingCover.style.setProperty('transform', `translate( 0, ${yPosition.y}%)`)})
    .onComplete(function () {looadingCover.parentNode.removeChild(document.getElementById("loading-text-intro")); TWEEN.remove(this)})

    introAnimation()
    ftsLoader.parentNode.removeChild(ftsLoader)

    window.scroll(0, 0)

}

/////////////////////////////////////////////////////////////////////////
//// DRACO LOADER TO LOAD DRACO COMPRESSED MODELS FROM BLENDER
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')
dracoLoader.setDecoderConfig({ type: 'js' })
const loader = new GLTFLoader(loadingManager)
loader.setDRACOLoader(dracoLoader)

/////////////////////////////////////////////////////////////////////////
///// DIV CONTAINER CREATION TO HOLD THREEJS EXPERIENCE
const container = document.getElementById('canvas-container')
const containerDetails = document.getElementById('canvas-container-details')

/////////////////////////////////////////////////////////////////////////
///// GENERAL VARIABLES
let gltfModel; // Declara la variable gltfModel modo global
let secondContainer = false
let width = container.clientWidth
let height = container.clientHeight

/////////////////////////////////////////////////////////////////////////
///// SCENE CREATION
const scene = new Scene()

/////////////////////////////////////////////////////////////////////////
///// RENDERER CONFIG
const renderer = new WebGLRenderer({ antialias: true, alpha: true, powerPreference: "high-performance"})
renderer.autoClear = true
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1))
renderer.setSize( width, height)
renderer.outputEncoding = sRGBEncoding
container.appendChild(renderer.domElement)

const renderer2 = new WebGLRenderer({ antialias: false})
renderer2.setPixelRatio(Math.min(window.devicePixelRatio, 1))
renderer2.setSize( width, height)
renderer2.outputEncoding = sRGBEncoding
containerDetails.appendChild(renderer2.domElement)

/////////////////////////////////////////////////////////////////////////
///// CAMERAS CONFIG
const cameraGroup = new Group()
scene.add(cameraGroup)

const camera = new PerspectiveCamera(35, width / height, 0.176005244255066, 1000)
camera.position.set(90,200.54,0.10000000149011612)
camera.rotation.set(0.47, 0.41, 0); // Rotation camera2

cameraGroup.add(camera)


const camera2 = new PerspectiveCamera(35, containerDetails.clientWidth / containerDetails.clientHeight, 1, 100);
camera2.position.set(0, 0, 5); // Position camera2
camera2.rotation.set(0, 0, 0); // Rotation camera2
scene.add(camera2);



//////// Para Lottie

window.addEventListener('beforeunload', () => {
    if (demoAnimation) {
        demoAnimation.destroy();
    }
});



/////////////////////////////////////////////////////////////////////////
///// MAKE EXPERIENCE FULL SCREEN
window.addEventListener('resize', () => {
    camera.aspect = container.clientWidth / container.clientHeight
    camera.updateProjectionMatrix()
    
    camera2.aspect = containerDetails.clientWidth / containerDetails.clientHeight
    camera2.updateProjectionMatrix()

    renderer.setSize(container.clientWidth, container.clientHeight)
    renderer2.setSize(containerDetails.clientWidth, containerDetails.clientHeight)

    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1))
    renderer2.setPixelRatio(Math.min(window.devicePixelRatio, 1))
})


/////////////////////////////////////////////////////////////////////////
///// SCENE LIGHTS
const sunLight = new DirectionalLight(0x435c72, 0.08)
sunLight.position.set(-100,0,-100)
scene.add(sunLight)

const fillLight = new PointLight(0x88b2d9, 2.7, 4, 3)
fillLight.position.set(30,3,1.8)
scene.add(fillLight)

const spotlightData = {
    "object": {
      "uuid": "ce565110-284d-4749-8b41-5fc135c787e3",
      "type": "SpotLight",
      "name": "SpotLightFOCO",
      "renderOrder": 2,
      "layers": 1,
      "matrix": [-0.8319079039703274,0.15146876066917353,0.5338412253217636,0,-0.37579293626830046,-0.46656576984263337,-0.4532339645804984,0,0.24017238163214105,-0.768970491484486,0.5924538887772224,0,-2.060165498368228,0.8474315244887345,2.0860048687953245,1],
      "up": [0,1,0],
      "color": 16777215, // White color
      "intensity": 0.5,
      "distance": 4.04,
      "angle": 1, // Adjust for cone size (smaller = more focused)
      "decay": 1,
      "penumbra": 1, // Ajuste la intensidad del borde aquí
      }
    };
// Extract properties from spotlight data
const FOCOSpotLight = new SpotLight(
spotlightData.object.color,
spotlightData.object.intensity,
spotlightData.object.distance,
spotlightData.object.angle,
spotlightData.object.penumbra,
);

const FOCOposition = new Vector3();
const FOCOdirection = new Vector3();
const FOCOmatrix = new Matrix4().fromArray(spotlightData.object.matrix);

FOCOposition.setFromMatrixPosition(FOCOmatrix);
FOCOdirection.set(0, 0, -1); // Assuming the spotlight points in the negative Z direction
FOCOdirection.transformDirection(FOCOmatrix);
FOCOSpotLight.position.copy(FOCOposition);
FOCOSpotLight.target.position.copy(FOCOposition.add(FOCOdirection)); // Set target for spotlight


scene.add(FOCOSpotLight); // Add the FOCOSpotLight to the scene

//////////////////////////////////////////////////////////////////////////
////////////  AÑADIR TINTINEO EN LA LUZ

const minIntensity = 0.00; // Intensidad mínima de la luz
const maxIntensity = 0.3; // Intensidad máxima de la luz
let time = 0;

function VariarIntensidadLuz() {
    requestAnimationFrame(VariarIntensidadLuz);

    // Incrementa el tiempo en cada iteración
    time += 0.07;

    // Calcula la intensidad utilizando una función sinusoidal
    const randomIntensity = (Math.sin(time) * 0.5 + 0.5) * (maxIntensity - minIntensity) + minIntensity;
    // Establece el valor de intensidad calculado en el foco de luz
    FOCOSpotLight.intensity = randomIntensity;

    renderer.render(scene, camera);
};

VariarIntensidadLuz(scene);

/////////////////////////////////////////////////////////////////////////
///// LOADING GLB/GLTF MODEL FROM BLENDER
loader.load('models/gltf/vivi_romano_mike.glb', function (gltf) {
    const targetMeshNames = ['Retopo_romano001CARALOWER', 'Retopo_romano002CUERPO'];
    const targetMeshCristal = ['pantallacristal', 'telefono_5','GRID'];
    const newMaterial = new MeshPhongMaterial({ shininess: 45 }); // Your desired material

    gltfModel = gltf; // Asigna el valor de gltf a la variable gltfModel de manera global

    gltf.scene.traverse((obj) => {
        if (obj.isMesh) {
            if (targetMeshNames.includes(obj.name)) {
                // Replace material for specific meshes
                obj.material = newMaterial;
            } else if (targetMeshCristal.includes(obj.name)) {
                // Use MeshStandardMaterial for emissive effect
                const emissiveMaterial = new MeshStandardMaterial({
                    color: 0x00ffff, // Color del material
                    emissive: 0xffffff, // Intensidad de emisión
                    // shininess: 100, // Aumentar el brillo
                });
                obj.material = emissiveMaterial;
            }
        }
    });

    scene.add(gltfModel.scene);
    clearScene();
    initLottieAnimation(); // Para Lottie, Llama a la inicialización después de que el modelo se haya cargado
});


function clearScene(){
    //oldMaterial.dispose()
    renderer.renderLists.dispose()
 }


////////////////////////////////////////////////////////
//////////////////// Para Lottie

/////////////////// let demoAnimation;

function initLottieAnimation() {
    demoAnimation = new LottieAnimation('demo-title');
    demoAnimation.init();
}


/////////////////////////////////////////////////////////////////////////
//// INTRO CAMERA ANIMATION USING TWEEN
function introAnimation() {
//    new TWEEN.Tween(camera.position.set(0,4,2.7)).to({ x: -1, y: 0, z: 8.8}, 3500).easing(TWEEN.Easing.Quadratic.InOut).start()
    new TWEEN.Tween(camera.position.set(0,4,2.7)).to({ x: 2.8, y: -3.8, z: 7.8}, 3500).easing(TWEEN.Easing.Quadratic.InOut).start()
    .onComplete(function () {
        TWEEN.remove(this)
        document.querySelector('.header').classList.add('ended')
        document.querySelector('.first>p').classList.add('ended')
    })
    
}

//////////////////////////////////////////////////
//// CLICK LISTENERS


document.getElementById('video').addEventListener('click', () => {
    document.getElementById('video').classList.add('active')
    document.getElementById('curiosidad').classList.remove('active')
    document.getElementById('web').classList.remove('active')
    document.getElementById('content').innerHTML = 'La televisión transforma luz, color y movimiento en una sinfonía visual que refleja los valores y deseos de una sociedad. Nos invita a sumergirnos en realidades alternativas y a cuestionar nuestra propia existencia. Actúa como un espejo dinámico, mostrando quiénes somos y cómo interactuamos con el mundo a nuestro alrededor.<br /><a href="https://www.muyespecial.com/blog/mosaico-principal/" class="demo-link"><span>vichar_loops</span></a>'

    const deltaRotation = {
        x: 0.0 - camera2.rotation.x,
        y: 0.1 - camera2.rotation.y,
        z: 0.0 - camera2.rotation.z,
    };
    animateCamera({ x: -0.7, y: -3.1, z: 7.0 },{ x: 0.4, y: -0.1, z: 0.0  }, deltaRotation); //cara romano

})

document.getElementById('web').addEventListener('click', () => {
    document.getElementById('web').classList.add('active')
    document.getElementById('video').classList.remove('active')
    document.getElementById('curiosidad').classList.remove('active')
    document.getElementById('content').innerHTML = 'Crear para la web es fusionar funcionalidad y creatividad en un lienzo vivo. Aquí, diseño e innovación convergen, permitiendo ideas transformadoras. Cada interfaz debe inspirar interacción y libertad mientras guía, sutilmente, hacia la narrativa deseada. Es un laboratorio dinámico donde el espectador deja de ser pasivo y participa activamente.<br /><a href="https://www.muyespecial.com/blog/mosaico-principal/" class="demo-link"><span>vichar_sites</span></a>'
 
    // Calcular diferencia de rotación
    const deltaRotation = {
        x: 0.0 - camera2.rotation.x,
        y: 0.0 - camera2.rotation.y,
        z: 0.0 - camera2.rotation.z,

    };

    animateCamera({ x: 0.4, y: 1.9, z: 6.0 },{  x: -0.3, y: 0.4, z: 0.0  }, deltaRotation); //cara mike

})

document.getElementById('curiosidad').addEventListener('click', () => {
    document.getElementById('curiosidad').classList.add('active')
    document.getElementById('video').classList.remove('active')
    document.getElementById('web').classList.remove('active')
    document.getElementById('content').innerHTML = 'La tecnología despierta nuestra creatividad con cada avance. Desde el tren de los Lumière hasta el open-source, seguimos reinventando la narrativa. Al compartir ideas, como el código abierto, las enriquecemos. Esta colaboración no nos hace vulnerables; fortalece nuestra conexión y demuestra que la imaginación no tiene límites ni barreras.<br /><a href="https://www.muyespecial.com/blog/mosaico-principal/" class="demo-link"><span>experimentos</span></a>'

    const deltaRotation = {
        y: 0.0 - camera2.rotation.y
    };
    animateCamera({ x: 10.0, y: -0.2, z: 2.3 },{  x: -0.6, y: 1.5, z: 0.5 }, deltaRotation); //mobile
    // bueno animateCamera({ x: 15.0, y: -0.2, z: 3.0 },{  x: -0.25, y: 1.5 }, deltaRotation); //mobile
});



/////////////////////////////////////////////////////////////////////////
//// ANIMATE CAMERA
function animateCamera(position, rotation){
    new TWEEN.Tween(camera2.position).to(position, 1800).easing(TWEEN.Easing.Quadratic.InOut).start()
    .onComplete(function () {
        TWEEN.remove(this)
    })
    new TWEEN.Tween(camera2.rotation).to(rotation, 2800).easing(TWEEN.Easing.Quadratic.InOut).start()
    .onComplete(function () {
        TWEEN.remove(this)
    })
}

/////////////////////////////////////////////////////////////////////////
//// PARALLAX CONFIG
const cursor = {x:0, y:0}
const clock = new Clock()
let previousTime = 0



/////////////////////////////////////////////////////////////////////////
//// RENDER LOOP FUNCTION
function rendeLoop() {

    if (!isAnimationPaused) {
    TWEEN.update()

    if (secondContainer){
        resetModelPosition(); // Restablecer la posición del modelo glTF en el contenedor 2
        renderer2.render(scene, camera2);
    } else {

                // Opcional: Restablecer la rotación del modelo glTF cuando no se renderiza en el contenedor 2
                if (gltfModel) {
                    //gltfModel.scene.rotation.x = -Math.PI / 8; // -22.5 grados en radianes en el eje X
                    //gltfModel.scene.rotation.y = -Math.PI / 7; // -25.71 grados en radianes en el eje Y
                    // Elimina la rotación del objeto
                    gltfModel.scene.rotation.set(0, 0, 0);
                }

            renderer.render(scene, camera)
        }

    // Animar los trazos
    //animateStrokes();
    
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    const parallaxY = cursor.y
    fillLight.position.y -= ( parallaxY *9 + fillLight.position.y -2) * deltaTime

    const parallaxX = cursor.x
    fillLight.position.x += (parallaxX *8 - fillLight.position.x) * 2 * deltaTime

    cameraGroup.position.z -= (parallaxY/3 + cameraGroup.position.z) * 2 * deltaTime
    cameraGroup.position.x += (parallaxX/3 - cameraGroup.position.x) * 2 * deltaTime

  }
    // Continuar el loop de animación solo si no está pausado
        requestAnimationFrame(rendeLoop);
}

rendeLoop()

function resetModelPosition() {
    if (gltfModel) { // Verifica si gltfModel está definida
        // Establece la posición original del modelo glTF en el contenedor 2
        gltfModel.scene.position.set(0, 0, 0); // Establece la posición deseada
        gltfModel.scene.rotation.set(0, 0, 0); // Establece la rotacion deseada
    }
};


//////////////////////////////////////////////////
//// ON MOUSE MOVE TO GET CAMERA POSITION
document.addEventListener('mousemove', (event) => {
    event.preventDefault()

    cursor.x = event.clientX / window.innerWidth -0.5
    cursor.y = event.clientY / window.innerHeight -0.5

    handleCursor(event)
}, false)

//////////////////////////////////////////////////
//// DISABLE RENDERER BASED ON CONTAINER VIEW
const watchedSection = document.querySelector('.second')

function obCallback(payload) {
    if (payload[0].intersectionRatio > 0.05){
        secondContainer = true
    }else{
        secondContainer = false
    }
}

const ob = new IntersectionObserver(obCallback, {
    threshold: 0.05
})

ob.observe(watchedSection)


//////////////////////////////////////////////////
//// MAGNETIC MENU
const btn = document.querySelectorAll('nav > .a')
const customCursor = document.querySelector('.cursor')

function update(e) {
    const span = this.querySelector('span')
    
    if(e.type === 'mouseleave') {
        span.style.cssText = ''
    } else {
        const { offsetX: x, offsetY: y } = e,{ offsetWidth: width, offsetHeight: height } = this,
        walk = 20, xWalk = (x / width) * (walk * 2) - walk, yWalk = (y / height) * (walk * 2) - walk
        span.style.cssText = `transform: translate(${xWalk}px, ${yWalk}px);`
    }
}

const handleCursor = (e) => {
    const x = e.clientX
    const y =  e.clientY
    customCursor.style.cssText =`left: ${x}px; top: ${y}px;`
}

btn.forEach(b => b.addEventListener('mousemove', update))
btn.forEach(b => b.addEventListener('mouseleave', update))
