// helpers/LottieHelper.js
import lottie from 'lottie-web';

export class LottieAnimation {
    constructor(containerId) {
        this.container = document.getElementById(containerId);
        this.animation = null;
        this.observer = null;
        this.resizeObserver = null;
    }

    async init() {
        try {
            // Importar el archivo JSON directamente
            // const animationData = await import('../static/models/LaDemo_titulo_anima-B-lottie.json');
            const animationData = await import('../../static/models/LaDemo_titulo_anima-B-lottie.json');

            // Crear el contenedor para la animación
            const lottieContainer = document.createElement('div');
            lottieContainer.style.width = '100%';
            lottieContainer.style.height = 'auto';
            lottieContainer.style.maxWidth = '100%';
            lottieContainer.style.margin = '0 auto';
            lottieContainer.style.position = 'relative'; // Añadido para mejor control del tamaño

            // Reemplazar el h1 existente
            const existingH1 = this.container.querySelector('h1');
            if (existingH1) {
                existingH1.replaceWith(lottieContainer);
            } else {
                this.container.appendChild(lottieContainer);
            }

            // Inicializar la animación
            this.animation = lottie.loadAnimation({
                container: lottieContainer,
                renderer: 'svg',
                loop: true,
                autoplay: false,
                animationData: animationData.default || animationData, // Manejar tanto módulos ES6 como CommonJS
            });

            // Ajustar tamaño inicial
            this.animation.addEventListener('DOMLoaded', () => {
                this.animation.resize();
            });

            // Configurar Intersection Observer
            this.setupObservers(lottieContainer);

        } catch (error) {
            console.error('Error loading Lottie animation:', error);
            // Mantener el h1 original si hay un error
            const existingH1 = this.container.querySelector('h1');
            if (existingH1) {
                existingH1.style.display = 'block';
            }
        }
    }

    setupObservers(container) {
        // Intersection Observer para controlar la reproducción
        this.observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (this.animation && !this.animation.isLoaded) {
                            this.animation.addEventListener('DOMLoaded', () => {
                                this.animation.play();
                            });
                        } else if (this.animation) {
                            this.animation.play();
                        }
                    } else {
                        this.animation?.pause();
                    }
                });
            },
            {
                threshold: 0.1,
                rootMargin: '50px 0px'
            }
        );

        // Resize Observer para mantener el tamaño correcto
        this.resizeObserver = new ResizeObserver(() => {
            if (this.animation) {
                this.animation.resize();
            }
        });

        if (container) {
            this.observer.observe(container);
            this.resizeObserver.observe(container);
        }
    }

    destroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        if (this.animation) {
            this.animation.destroy();
        }
    }

    pauseAnimation() {
        if (this.animation && this.animation.isLoaded) {
            this.animation.pause();
        }
    }

    playAnimation() {
        if (this.animation && this.animation.isLoaded) {
            this.animation.play();
        }
    }
}
